import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import {
  DefaultComparisonSortKeys,
  FilterOperators,
  MULTI_TREE_FIRST_PAGE,
  MultiTreeFilterType,
  PaginationLimits,
  SESSION_REQ_INTERVAL,
  SessionStatus,
  SessionTypes,
  SortDirection,
} from "src/utils/types";
import {
  useGetSession,
  useGetLogs,
  useGetResults,
  useGetSortedMultiTreeGenerations,
  useGetSortedMultiTreeTestResuls,
  useGetSortedMultiTreePopulations,
  useGetgetLastMultiTreeGeneration,
} from "./sessions";
import { useGetProject } from "./projects";
import { useGetConfigs } from "./configurations";

const sessionContext = createContext();

export const MultitreeSessionPageProvider = ({ children }) => {
  const params = useParams();
  const sessionId = params.sessionId;
  const projectId = params.id;

  const [editing, setEditing] = useState(false);
  const [activeSession, setActiveSession] = useState(null);
  const [filteredSolutions, setFilteredSolutions] = useState(null);
  const [generationResults, setGenerationResults] = useState(null);

  const [trainingPagination, setTrainingPagination] = useState({
    page: MULTI_TREE_FIRST_PAGE,
    limit: PaginationLimits._5,
  });
  const [trainingSort, setTrainingSort] = useState({
    key: "fitness",
    order: SortDirection.DESC,
  });

  const [comparisonPagination, setComparisonPagination] = useState({
    page: MULTI_TREE_FIRST_PAGE,
    limit: PaginationLimits._5,
    itemCount: 0,
  });
  const [comparisonSort, setComparisonSort] = useState({
    key: DefaultComparisonSortKeys.GENERATION,
    order: SortDirection.DESC,
  });
  const [comparisonFilters, setComparisonFilters] = useState([]);
  const [comparisonFirstRequest, setComparisonFirstRequest] = useState(true);

  const { data: session } = useGetSession(sessionId);
  const { data: project } = useGetProject(projectId);
  const { data: configurations } = useGetConfigs(projectId);

  // TODO: bookmark is same as single?
  const isTestSession = Boolean(
    activeSession?.properties?.commandType == SessionTypes.TEST
  );

  const isTrainSession = Boolean(
    activeSession?.properties?.commandType == SessionTypes.TRAIN
  );

  const [sessionStatus, setSessionStatus] = useState(activeSession?.status);
  const [running, setRunning] = useState(
    sessionStatus === SessionStatus.RUNNING
  );

  const [intervalMs, setIntervalMs] = useState(
    (sessionStatus !== SessionStatus.FINISHED &&
      sessionStatus !== SessionStatus.KILLED) ||
      running
      ? SESSION_REQ_INTERVAL
      : false
  );

  const [populationIntervalMs, setPopulationIntervalMs] = useState(
    (sessionStatus !== SessionStatus.FINISHED &&
      sessionStatus !== SessionStatus.KILLED) ||
      running
      ? SESSION_REQ_INTERVAL
      : false
  );

  const { data: results } = useGetResults({
    sessionId: sessionId,
    intervalMs: intervalMs,
  });

  const { data: logs } = useGetLogs({
    sessionId: sessionId,
    intervalMs: intervalMs,
  });

  const { mutateAsync: getSortedTestResults } = useGetSortedMultiTreeTestResuls(
    {
      sessionId: sessionId,
      enabled: Boolean(isTestSession),
    }
  );

  const { mutateAsync: getSortedTrainResults } =
    useGetSortedMultiTreeGenerations({
      sessionId: sessionId,
      enabled: Boolean(isTrainSession),
    });

  const { data: lastGenerationData } = useGetgetLastMultiTreeGeneration({
    sessionId: sessionId,
    enabled: Boolean(comparisonFirstRequest) && Boolean(sessionId),
  });

  const { mutateAsync: getSortedPopulations } =
    useGetSortedMultiTreePopulations({
      sessionId: sessionId,
      enabled:
        Boolean(isTrainSession) &&
        Boolean(sessionId) &&
        Boolean(!comparisonFirstRequest),
    });

  useEffect(() => {
    if (lastGenerationData && comparisonFirstRequest) {
      let lastGeneration;
      const { results } = lastGenerationData;
      if (results.length > 0) {
        lastGeneration = results[0].generation;
        const filters = [
          {
            key: DefaultComparisonSortKeys.GENERATION,
            op: FilterOperators.MIN,
            value: Number(lastGeneration),
            metric: MultiTreeFilterType.MODEL,
          },
          {
            key: DefaultComparisonSortKeys.GENERATION,
            op: FilterOperators.MAX,
            value: Number(lastGeneration),
            metric: MultiTreeFilterType.MODEL,
          },
        ];
        setComparisonFilters(filters);
      }
      setComparisonFirstRequest(false);
      setFilteredSolutions(results);
    }
  }, [lastGenerationData]);

  useEffect(() => {
    if (session?.data) {
      setActiveSession(session.data);
    }
  }, [session?.data]);

  // for training
  useEffect(() => {
    if (!session) return;
    let interval;

    const fetchData = async () => {
      try {
        const data = isTrainSession
          ? await getSortedTrainResults({
              sortInfo: { ...trainingSort },
              paginationInfo: { ...trainingPagination },
            })
          : await getSortedTestResults({
              sortInfo: { ...trainingSort },
              paginationInfo: { ...trainingPagination },
            });
        setGenerationResults(data);
      } catch (error) {
        console.error("Error fetching generations:", error);
      }
    };

    // Fetch data immediately when sort, page or interval changes
    fetchData();

    if (intervalMs || true) {
      interval = setInterval(() => {
        fetchData();
      }, SESSION_REQ_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [
    trainingSort.key,
    trainingSort.order,
    intervalMs,
    isTrainSession,
    trainingPagination.page,
    trainingPagination.limit,
  ]);

  // for comparison
  useEffect(() => {
    if (!session) return;
    let interval;

    const fetchData = async () => {
      try {
        let sortObj = {};
        const sortOrder = comparisonSort.order === SortDirection.ASC ? 1 : -1
        if(comparisonSort.key == DefaultComparisonSortKeys.GENERATION){
          sortObj = {
            generation: sortOrder,
            individual: 1
          }
        }else{
          sortObj[comparisonSort.key] = sortOrder
        }

        const data = await getSortedPopulations({
          sortInfo: sortObj,
          paginationInfo: comparisonPagination,
          filterInfo: comparisonFilters,
        });
        setComparisonPagination((prev) => ({ ...prev, itemCount: data.count }));
        setFilteredSolutions(data.results);
        setPopulationIntervalMs(false);
      } catch (error) {
        console.error("Error fetching populations:", error);
      }
    };

    // Fetch data immediately when sort, page or interval changes
    fetchData();

    if (populationIntervalMs) {
      interval = setInterval(() => {
        fetchData();
      }, SESSION_REQ_INTERVAL);
    }

    return () => clearInterval(interval);
  }, [
    comparisonSort.key,
    comparisonSort.order,
    populationIntervalMs,
    isTrainSession,
    comparisonPagination.page,
    comparisonPagination.limit,
    JSON.stringify(comparisonFilters),
  ]);

  useEffect(() => {
    if (sessionStatus !== results?.status) {
      setSessionStatus(results?.status);
    }

    if (
      results?.status === SessionStatus.FINISHED ||
      results?.status === SessionStatus.KILLED ||
      results?.status === SessionStatus.FAILED
    ) {
      setRunning(false);
    } else if (results?.status === SessionStatus.RUNNING) {
      setRunning(true);
    }
  }, [results?.status]);

  useEffect(() => {
    running ? setIntervalMs(SESSION_REQ_INTERVAL) : setIntervalMs(false);
  }, [running]);

  const combinedTreeList = useMemo(() => {
    if ((filteredSolutions || []).length == 0) {
      return [];
    }

    let individuals = [];
    filteredSolutions.forEach((model) => {
      (model.trees || []).forEach((tree, index) => {
        individuals.push({
          generation: model.generation || 0,
          sessionId: sessionId,
          resultId: model.id,
          model: tree.expr,
          size: tree.size,
          fitness: tree.fitness,
          id: model.id,
          individual: model.individual,
          treeIndex: index,
          modelFitness: model.fitness,
          modelSize: model.size,
          modelOther: model.other,
        });
      });
    });

    return individuals;
  }, [filteredSolutions]);

  // console.log("filteredSolutions", filteredSolutions);

  const data = {
    sessionId,
    selectedSession: activeSession?.id
      ? { value: activeSession.id, label: activeSession.name }
      : null,
    sessionStatus,
    setSessionStatus,
    running,
    setRunning,
    intervalMs,
    setIntervalMs,
    editing,
    setEditing,
    isTestSession,
    isTrainSession,
    generationResults,
    logs: logs?.data,
    session: activeSession,
    project: project?.data,
    configurations: configurations?.data || [],
    filteredSolutions,
    setFilteredSolutions,
    trainingSort,
    setTrainingSort,
    trainingPagination,
    setTrainingPagination,
    comparisonSort,
    setComparisonSort,
    comparisonPagination,
    setComparisonPagination,
    comparisonFilters,
    setComparisonFilters,
    combinedTreeList,
  };

  return (
    <sessionContext.Provider value={data}>{children}</sessionContext.Provider>
  );
};

export const useMultiTreeSession = () => useContext(sessionContext);
